import { Link } from '@remix-run/react'
import { Button } from './ui/button'

export function NotFoundPage() {
	return (
		<div className="flex min-h-[calc(100vh-80px)] flex-col items-center justify-center">
			<div className="relative w-full max-w-[1400px] px-8">
				{/* Background circle effect */}
				<div className="absolute right-0 top-1/2 -z-10 h-[800px] w-[800px] -translate-y-1/2 rounded-full bg-[#E8F0FE]/40" />

				<div className="grid grid-cols-1 items-center gap-12 lg:grid-cols-[45%_55%]">
					<div>
						<h1 className="text-7xl font-bold text-primary">It's Empty Here</h1>

						<p className="mt-6 text-xl text-muted-foreground">
							Looks like this page can't be found. Maybe it was moved or renamed
						</p>

						<Button
							asChild
							variant="outline"
							className="mt-12 rounded-full px-8 py-6 text-lg"
						>
							<Link to="/">Back to Homepage</Link>
						</Button>
					</div>

					<div className="flex justify-end">
						<img
							src="/404.svg"
							alt="Robot looking at empty box"
							className="w-full max-w-[1000px]"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
